import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import { useLocation } from 'react-router-dom';
import history from '../../history';
import { setActiveCompany } from '../../actions/company';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../selectors/company';
import logoImage from './logo.png';
import Button from '../../components/inputs/Button';
import UserBadge from '../../components/UserBadge';
import { getLoggedInUser } from '../../selectors/users';
import { signOut } from '../../actions/auth';
import IdleSessionHandler from '../../components/IdleSessionTimeout/IdleSessionHandler';
import Modal from '../../components/Modal';

import styles from './topHeader.module.scss';
const bStyles = classnames.bind(styles);

export default function TopHeader() {
  const loggedInUser = useSelector(getLoggedInUser);
  const activeCompany = useSelector(getActiveCompany);
  const activeGroup = useSelector(getActiveLocationId);
  const activeProject = useSelector(getActiveProject);
  const dispatch = useDispatch();
  const [openPageOptions, setOpenPageOptions] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const getButtonText = () => {
    let buttonText = '';

    if (activeProject) {
      buttonText = `${activeCompany.name}: ${activeGroup.name}: ${activeProject.name}`;
    } else if (activeGroup) {
      buttonText = `${activeCompany.name}: ${activeGroup.name}`;
    } else if (activeCompany) {
      buttonText = `${activeCompany.name}`;
    }

    return buttonText;
  };
  const location = useLocation();
  const token = sessionStorage.getItem('token');

  const noHeaderInfoPaths = [
    '/login',
    '/externalLogin',
    '/externalWitness',
    '/home'
  ];
  const hasHeaderInteractions = !noHeaderInfoPaths.some(h =>
    location.pathname.includes(h)
  );

  window.onclick = function(event) {
    if (openPageOptions) {
      const target = event.target.id;
      const shouldClose =
        target !== 'headerUserBadge' &&
        target !== 'headerPageAction' &&
        target !== 'userBadge' &&
        target !== 'badgeProfilePic' &&
        target !== 'badgeUserFullName' &&
        target !== 'headerPageOptionsChevron';
      if (shouldClose) setOpenPageOptions(false);
    }
  };

  return (
    <header className={styles.topNav}>
      <div className={styles.leftSide}>
        <Modal
          isOpen={openLogoutModal}
          title="Confirm Logout"
          onRequestClose={() => setOpenLogoutModal(false)}
          submitActions={() => {
            dispatch(signOut());
            setOpenLogoutModal(false);
          }}
          submitButtonText="Logout"
          submitButtonColor="blue"
          titleClassName="blueHeader"
        >
          Are you sure you want to logout? All unsaved work will be discarded.
        </Modal>
        {location.pathname !== '/login' &&
          process.env.REACT_APP_STAGE !== 'development ' && (
            <IdleSessionHandler />
          )}
        <div
          className={styles.logoContainer}
          onClick={() =>
            activeCompany?._id && hasHeaderInteractions && loggedInUser._id
              ? dispatch(setActiveCompany({})) && history.push('/home')
              : null
          }
        >
          <img src={logoImage} alt="iReportSource logo" />
        </div>
        {loggedInUser._id &&
          activeCompany._id &&
          hasHeaderInteractions &&
          token && (
            <div className={styles.locationContainer}>
              <div className={styles.locationContainerText}> Location:</div>
              <Button
                text={getButtonText()}
                color="white"
                className={styles.locationButton}
                onClick={() => history.push('/app/dashboard')}
              />
            </div>
          )}
      </div>
      <div className={styles.rightSide}>
        {loggedInUser._id && token ? (
          <div
            className={styles.pageActions}
            onClick={() => setOpenPageOptions(!openPageOptions)}
            id="headerPageAction"
          >
            <UserBadge userId={loggedInUser._id} showName showImage />
            <img
              src={require('../../assets/images/whiteChevron.png')}
              alt={`actions for ${loggedInUser.username}`}
              className={styles.chevron}
              style={{
                transform: openPageOptions ? 'rotate(180deg)' : ''
              }}
              id="headerPageOptionsChevron"
            />
            {openPageOptions ? (
              <div className={styles.dropdown}>
                {location.pathname === '/home' ||
                location.pathname === '/app/profile' ? null : (
                  <div
                    onClick={() => history.push('/app/profile')}
                    className={bStyles('dropdownOption', 'blueOutline')}
                  >
                    Profile
                  </div>
                )}
                <div
                  onClick={() => setOpenLogoutModal(true)}
                  className={bStyles('dropdownOption', 'blueOutline')}
                >
                  Log out
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </header>
  );
}
